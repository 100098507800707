import React from "react"
import { Link } from "gatsby"
// import "../../styles/new-landing.css"
import "../../styles/welcomeMoofarm.css"
import BagIcon from "../../images/landing/bagimg.png"
import { Helmet } from "react-helmet"
import checkList from "../../images/welcomeMoofarm/check-list.webp"
import { event } from "jquery"

class WelcomeMoofarm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ques1option1: 'yes',
      ques1option2: 'no',
      selectedOption1: "",
      ques2option1: 'मुर्राह',
      ques2option2: 'एच',
      ques2option3: 'गिर',
      ques2option4: 'अन्य',
      selectedOption2: "",
      customerMobile: ''
    }
  }

  componentDidMount = () => {
    this.setState({
      customerMobile: sessionStorage.getItem("CustMobileNumber")
    })
    // sessionStorage.getItem("CustMobileNumber")
  }

  handleQuestion1 = (event) => {
    this.setState({ selectedOption1: event })
  }

  handleQuestion2 = (event) => {
    this.setState({ selectedOption2: event })
  }

  handleSubmitSurvey = async () => {
    if (this.state.selectedOption1 === '') {
      alert("Please select any options.")
      return;
    }
    let answer1 = {
      "ContactNo": Number(this.state.customerMobile),
      "QuestionID": 1,
      "AnswerID": this.state.selectedOption1 === 'yes' ? 1 : 2
    }
    let answer2 = {
      "ContactNo": Number(this.state.customerMobile),
      "QuestionID": 2,
      "AnswerID":
        this.state.selectedOption2 === 'मुर्राह' ? 1 :
          this.state.selectedOption2 === 'एच' ? 2 :
            this.state.selectedOption2 === 'गिर' ? 3 :
              this.state.selectedOption2 === 'अन्य' ? 4 :
                alert("Please select any options.")
    }
    let payload = {
      "data": [
        answer1,
        answer2
      ]
    }

    const rawResponse = await fetch('https://api.mooofarm.com/v3/api/sell_catalog/survey', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
    const content = await rawResponse.json();
    if (content.status === 'success') {
      window.location.href = "/Thanks"
    } else {
      alert("Something went wrong. Please try again.")
    }

  }

  render() {
    return (
      <div className="main-container">
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700&display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
          />
          <script src="js/script.js"></script>
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
        </Helmet>
        <div className="container main-div">
          <div className="top_banner">
            <div className="top_banner_view">
              <img src={checkList} />
              <h2>अभिनंदन!</h2>
              <p>हमारी टीम आपसे तुरंत संपर्क करेगी</p>
            </div>
          </div>
          <div className="priceAirn">
            <h3>पाइये इनाम पाने का मौका!</h3>
            <p>दिए गए बस 2 सवालों के जवाब दीजिये</p>
          </div>
          <div className="question">
            <p>1. क्या आप इसी महीने में पशु खरीदना चाहते हैं?</p>
            <form>
              <div className="rediobtn">
                <input
                  type="radio"
                  name="priceAnsFirst"
                  value={this.state.ques1option1}
                  onChange={(e) => this.handleQuestion1(e.target.value)}
                />
                <p>हाँ</p>
              </div>
              <div className="rediobtn">
                <input
                  type="radio"
                  name="priceAnsFirst"
                  value={this.state.ques1option2}
                  onChange={(e) => this.handleQuestion1(e.target.value)}
                />
                <p>नहीं, इस महीने के बाद </p>
              </div>
            </form>
          </div>
          <div className="question">
            <p>2. आप किस नस्ल का पशु खरीदने की इच्छा रखते हैं?</p>
            <form>
              <div className="rediobtn">
                <input
                  type="radio"
                  name="priceAns"
                  value={this.state.ques2option1}
                  onChange={(e) => this.handleQuestion2(e.target.value)}
                />
                <p>मुर्राह / मुर्राह क्रॉस </p>
              </div>
              <div className="rediobtn">
                <input
                  type="radio"
                  name="priceAns"
                  value={this.state.ques2option2}
                  onChange={(e) => this.handleQuestion2(e.target.value)}
                />
                <p>एच. ऍफ़. / एच. ऍफ़. क्रॉस</p>
              </div>
              <div className="rediobtn">
                <input
                  type="radio"
                  name="priceAns"
                  value={this.state.ques2option3}
                  onChange={(e) => this.handleQuestion2(e.target.value)}
                />
                <p>गिर </p>
              </div>
              <div className="rediobtn">
                <input
                  type="radio"
                  name="priceAns"
                  value={this.state.ques2option4}
                  onChange={(e) => this.handleQuestion2(e.target.value)}
                />
                <p>अन्य </p>
              </div>
            </form>
          </div>
          <div className="row feed-popup-button-row text-center p-0">
            <div className="col-xs-12">
              {/* <Link to="/Thanks"> */}
              {/* <div className="popup-button-view"> */}
              <button className="popup-button-view" onClick={() => { this.handleSubmitSurvey() }}>
                जमा करे
              </button>
              {/* </div> */}
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WelcomeMoofarm;
